@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.text-module {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: 600px;
  color: inherit;
  margin: 0;
  padding: functions.spacing(4);

  @include breakpoints.down(lg) {
    min-width: unset;
  }

  &:global {
    &.reset-gap {
      gap: 0;
    }

    &.left {
      align-items: flex-start;
      text-align: left;

      :global(.cta-container) {
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: auto;

        @include breakpoints.down(lg) {
          align-items: center;
          justify-content: center;
        }

        @include breakpoints.up(xxl) {
          margin-top: 0;
        }
      }
    }

    &.right {
      align-items: flex-end;
      text-align: right;

      :global(.cta-container) {
        align-items: flex-end;
        justify-content: flex-end;
        margin-left: auto;

        @include breakpoints.down(lg) {
          align-items: center;
          justify-content: center;
        }

        @include breakpoints.up(xxl) {
          margin-top: 0;
        }
      }
    }

    &.column-2 {
      @include breakpoints.up(xxl) {
        flex-direction: row;
        gap: 2.5rem;
        text-align: left;
        align-items: flex-end;
      }

      :global(.cta-container) {
        align-items: flex-start;

        @include breakpoints.up(xxl) {
          justify-content: flex-end;
          margin-left: auto;
          margin-top: 0;

          a {
            width: auto;
          }
        }
      }
    }

    &.hide-above-tablet {
      @include breakpoints.up(1025px) {
        display: none;
      }
    }

    &.hide-below-tablet {
      @include breakpoints.down(lg) {
        display: none;
      }
    }
  }

  .teaser-title {
    margin: 0.25rem 0;
    font-weight: 900;
  }

  .teaser-pretitle {
    font-weight: 700;
    font-size: 1.25rem;
  }

  .teaser-text {
    :global(.text-small) {
      font-size: functions.px-to-rem(12px);
    }

    :global(.text-large) {
      font-size: functions.px-to-rem(20px);
      line-height: 1.7;

      @include breakpoints.up(sm) {
        font-size: functions.px-to-rem(40px);
        line-height: 1.4;
      }
    }

    p {
      margin: 0.5rem 0;
    }

    a {
      color: var(--primary-dark);
      font-weight: var(--font-weight-bold);
    }

    h1 {
      font-size: functions.px-to-rem(60px);
    }

    h2 {
      font-size: functions.px-to-rem(40px);
    }

    h3 {
      font-size: functions.px-to-rem(32px);
    }
  }

  :global(.cta-container) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;
    margin-top: 1rem;

    a {
      width: auto;
    }

    @include breakpoints.up(md) {
      flex-direction: row;
      justify-content: center;
    }
  }
}
.productContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
